import React from 'react';
import Header from '@ahk/backend-ui/Header';
import Footer from '@ahk/backend-ui/Footer';
import AppLogo from '../../assets/images/app_logo.svg';
import HeadlineImage from '../../assets/images/header_schriftzug.svg';
import UserContext from '../../context/UserContext';

class CommonPage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const user = this.context;
        this.setClassNameToAppRoot(this.props.className);

        const links = [
            { label: 'Versendete Vorgänge', linkTo: '/vorgaenge/', exact: true },
            { label: 'Neuer Vorgang', linkTo: '/vorgaenge/neu/' }
        ];

        return (
            <React.Fragment>
                <Header
                    appLogo={AppLogo}
                    headline={p => <img src={HeadlineImage} />}
                    subline="Restwertfinanzierung für Bestandskunden"
                    user={user && user.username ? user : null}
                    navItems={links} />

                <div id="page_content">
                    {this.props.children}
                </div>

                <Footer />

            </React.Fragment>
        );
    }

    setClassNameToAppRoot(className) {
        if (typeof window !== 'undefined' && window.document) {
            const appRoot = document.getElementById('app_root');
            if (appRoot) {
                appRoot.removeAttribute('class');
                if (className) {
                    appRoot.setAttribute('class', className);
                }
            }
        }
    }
}

CommonPage.contextType = UserContext;

export default CommonPage;