import './login.scss';

import React from 'react';
import LoginForm from '@ahk/backend-ui/LoginForm';
import jwt from 'jsonwebtoken';
import CommonPage from '../CommonPage';

import "isomorphic-fetch";

class LoginPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            authFailed: false,
            isResetOngoing: false
        };
    }

    async handleSubmit(ev, { username, passwd }) {
        ev.preventDefault();
        ev.stopPropagation();

        try {
            const result = await fetch(
                window.BASE_URL + '/api/v1.0/auth/login',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic ' + window.btoa(username + ':' + passwd)
                    }
                });

            if (result.status === 200) {
                this.redirectBy(await result.json());
            } else {
                this.setState({ authFailed: true });
            }

        } catch (err) {
            this.setState({ authFailed: true });
        } finally {
            return false;
        }
    }

    async handlePasswdReset(ev, { username }) {
        ev.preventDefault();
        ev.stopPropagation();

        this.setState({ isResetOngoing: true });

        try {
            const result = await fetch(
                window.BASE_URL + '/api/v1.0/auth/reset',
                {
                    method: 'POST',
                    body: JSON.stringify({ username }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

            if (result.status !== 201) {
                this.setState({ authFailed: true });
            }

        } catch (err) {
            this.setState({ authFailed: true });
        } finally {
            return false;
        }
    }

    render() {
        const { isResetOngoing } = this.state;

        return (
            <CommonPage className="page-login">
                <div className="page-login--content">
                    {
                        isResetOngoing
                            ? this.renderResetHint()
                            : this.renderLoginForm()
                    }
                </div>
            </CommonPage>
        );
    }

    renderLoginForm() {
        const { authFailed } = this.state;

        return (
            <LoginForm
                usernameLabel="Benutzername / E-Mail"
                onSubmit={this.handleSubmit.bind(this)}
                onReset={this.handlePasswdReset.bind(this)}
                authFailed={authFailed} />
        );
    }

    renderResetHint() {
        const { authFailed } = this.state;
        const hintText = authFailed === true
            ? (<span>Beim Zurücksetzen ging etwas schief, bitte kontektiere <br /><br /><a href="mailto:it@autohaus-koenig.de">it@autohaus-koenig.de</a></span>)
            : <span>Deine Anfrage zum Zurücksetzen des Passwortes wurde im System hinterlegt. Du solltest in Kürze eine E-Mail mit einem Link erhalten.</span>;

        return (
            <div className="page-login--reset-hint">{hintText}</div>
        );
    }

    redirectBy({ token }) {
        const validRoles = [
            'sachbearbeiter', 'supervisor'
        ];

        const { role = [] } = jwt.decode(token);
        const roles = role.filter(r => r && validRoles.indexOf(r) !== -1)

        if (roles.length === 0) {
            this.setState({ authFailed: true });
            return;
        }

        
        if (typeof window !== 'undefined' && window.location) {
            if(roles.indexOf('supervisor') !== -1){
                window.location.assign('/vorgaenge/');
            } else {
                window.location.assign('/vorgaenge/neu/');
            }
        }
    }
}

export default LoginPage;