import "./dossier-form.scss";

import React from "react";
import Select from "@ahk/backend-ui/Select";
import Input from "@ahk/backend-ui/Input";
import VinHelper from "@ahk/utils/VinHelper";

const CSS_FORM = "dossier--form";

function FormElement(props) {
	const { type, suffix, className } = props;
	const classes = [CSS_FORM + "--element", CSS_FORM + "--element--" + suffix];

	if (className) {
		classes.push(className);
	}

	return (
		<div className={classes.join(" ")}>
			{type === "select" && <Select {...props} />}
			{type === "textfield" && <Input {...props} />}
		</div>
	);
}

function NaturalPersonFields(props) {
	const { salutation, firstname = "", lastname = "", onChange } = props;

	return (
		<div className={CSS_FORM + "--person"}>
			<FormElement
				type="select"
				suffix="salutation"
				label="Anrede"
				value={salutation}
				options={["Herr", "Frau"]}
				onChange={onChange && onChange.bind(null, "salutation")}
			/>

			<FormElement
				type="textfield"
				suffix="firstname"
				label="Vorname"
				value={firstname}
				onChange={(ev) => onChange && onChange("firstname", ev.target.value)}
			/>

			<FormElement
				type="textfield"
				suffix="lastname"
				label="Nachname"
				value={lastname}
				onChange={(ev) => onChange && onChange("lastname", ev.target.value)}
			/>
		</div>
	);
}

function DossierForm(props) {
	const {
		company = "",
		street = "",
		nr = "",
		postal_code = "",
		location = "",
		email = "",
		phone = "",
		vin = "",
		residual_value = "",
		onChange,
		sales_type = ""
	} = props;

	const handleTextfieldChange = (key, ev) => {
		onChange && onChange(key, ev.target.value);
	};

	const handleVinChange = (ev) => {
		const { value } = ev.target;

		onChange && onChange("vin", value.toUpperCase());
	};

	return (
		<div className={CSS_FORM}>
			<NaturalPersonFields {...props} />

			{sales_type === 'commercial' &&
				<div className={CSS_FORM + "--company"}>
					<FormElement
						type="textfield"
						suffix="company"
						label="Firma"
						value={company}
						onChange={(ev) => onChange("company", ev.target.value)}
						className="width-100"
					/>
				</div>
			}

			<div className={CSS_FORM + "--address"}>
				<FormElement
					type="textfield"
					suffix="street"
					label="Straße"
					value={street}
					onChange={handleTextfieldChange.bind(null, "street")}
					isRequired
				/>

				<FormElement
					type="textfield"
					suffix="nr"
					label="Nr."
					value={nr}
					onChange={handleTextfieldChange.bind(null, "nr")}
					isRequired
				/>

				<FormElement
					type="textfield"
					suffix="postal-code"
					name="postal_code"
					label="PLZ"
					value={postal_code}
					onChange={handleTextfieldChange.bind(null, "postal_code")}
					isRequired
				/>

				<FormElement
					type="textfield"
					suffix="location"
					label="Ort"
					value={location}
					onChange={handleTextfieldChange.bind(null, "location")}
					isRequired
				/>
			</div>

			<div className={CSS_FORM + "--communication"}>
				<FormElement
					type="textfield"
					suffix="email"
					label="E-Mail"
					value={email}
					onChange={handleTextfieldChange.bind(null, "email")}
					isEmailField
					isRequired
				/>

				<FormElement
					type="textfield"
					suffix="phone"
					label="Telefon"
					value={phone}
					onChange={handleTextfieldChange.bind(null, "phone")}
					isRequired
				/>
			</div>

			<div className={CSS_FORM + "--car"}>
				<FormElement
					type="textfield"
					suffix="vin"
					label="Fahrgestellnummer (17-stellig)"
					value={vin.toUpperCase()}
					errorText={vin.length === 0 ? "" : VinHelper.getErrorText(vin)}
					onChange={handleVinChange}
					isRequired
				/>

				<FormElement
					type="textfield"
					suffix="residual_value"
					label="Finanzierungssumme (Restwert des Fahrzeuges)"
					value={residual_value}
					onChange={handleTextfieldChange.bind(null, "residual_value")}
					isRequired
				/>
			</div>
		</div>
	);
}

export default DossierForm;
