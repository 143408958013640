import React from 'react';
import AnswerToggle from '@ahk/backend-ui/AnswerToggle';

const CSS_QUESTION = 'page-neuer-vorgang--content--questions';

function QuestionSection(props) {
    const {
        onChange = () => {
        }
    } = props;

    const handleAnswer = (key, answer) => {
        onChange(key, answer);
    };

    return (
        <div className={CSS_QUESTION}>
            <ToggleQuestion
                cssSuffix="sales-type"
                question="Ist dein Vorgang privat oder gewerblich?"
                answers={['Privat', 'Gewerbe']}
                onChange={idx => handleAnswer('sales_type', idx === 0 ? 'private' : 'commercial')} />
        </div>
    );
}

function ToggleQuestion({ cssSuffix, question, answers, onChange}) {
    const classes = [CSS_QUESTION + '--' + cssSuffix];

    return (
        <div className={classes.join(' ')}>
            <b className={CSS_QUESTION + "--text"}>{question}</b>
            <AnswerToggle items={answers} onChange={onChange} />
        </div>
    );
}

export default QuestionSection;