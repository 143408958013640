import './registration.scss';

import React from 'react';
import Header from '@ahk/backend-ui/Header';
import Footer from '@ahk/backend-ui/Footer';
import Input from '@ahk/backend-ui/Input';
import Button from '@ahk/backend-ui/Button';
import ApiHelper from '../../../helper/ApiHelper';

class RegistrationPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            hasValidToken: true,
            username: '',
            passwd: '',
            passwd_confirmation: ''
        };
    }

    async componentDidMount() {
        const match = /.*token=(.+);?.*/ig.exec(window.location.search);

        if (match && match[1]) {
            const token = match[1];
            try {
                const registrationData = await ApiHelper.GET('/registrations?token=' + token);

                this.setState({
                    hasValidToken: typeof registrationData !== 'undefined',
                    token,
                    ...registrationData
                });
            } catch (err) {
                this.setState({ hasValidToken: false });
            }
        }
    }

    isValid() {
        const { username, passwd, passwd_confirmation } = this.state;
        const isSet = val => typeof val !== 'undefined' && val !== null && val.length > 0;

        if (!isSet(username)) {
            return false;
        }

        if (!isSet(passwd)) {
            return false;
        }

        if (!isSet(passwd_confirmation)) {
            return false;
        }

        if (passwd !== passwd_confirmation) {
            return false;
        }

        if (passwd.length < 8) {
            return false;
        }

        if (username.indexOf('@') !== -1) {
            return false;
        }

        return true;
    }

    validateUsername(val) {
        if (val.indexOf('@') !== -1) {
            this.setState({ usernameError: 'Der Benutzername enthält ungültige Zeichen.' });
            return;
        }

        this.setState({ usernameError: '' });
    }

    validatePassword(passwd) {
        const { passwd_confirmation } = this.state;

        if (passwd_confirmation.length > 0 && passwd_confirmation !== passwd) {
            this.setState({ passwordError: 'Die eingegebenen Passwörter stimmen nicht überein.' });
            return;
        }

        if (passwd.length < 8) {
            this.setState({ passwordError: 'Das eingegebene Password ist zu kurz. (mind. 8 Zeichen)' });
            return;
        }

        this.setState({
            passwordError: '',
            passwordConfirmationError: ''
        });
    }

    validatePasswordConfirmation(passwd_confirmation) {
        const { passwd } = this.state;

        if (passwd.length > 0) {
            if (passwd_confirmation !== passwd) {
                this.setState({ passwordConfirmationError: 'Die eingegebenen Passwörter stimmen nicht überein.' });
                return;
            }
        }

        if (passwd_confirmation.length < 8) {
            this.setState({ passwordConfirmationError: 'Das eingegebene Password ist zu kurz. (mind. 8 Zeichen)' });
            return;
        }

        this.setState({
            passwordError: '',
            passwordConfirmationError: ''
        });
    }

    async handleSubmit() {
        const { uid, username, passwd, passwd_confirmation, token } = this.state;

        try {
            await ApiHelper.POST('/registrations', {
                uid, username, passwd, passwd_confirmation, token
            });

            /* istanbul ignore else */
            if (typeof window !== 'undefined' && window.location) {
                window.location.assign('/login');
            }
        } catch (err) {
            this.setState({ hasValidToken: false });
        }
    }

    render() {
        const {
            hasValidToken,
            name_suggestion = '',
            username, passwd, passwd_confirmation,
            usernameError, passwordError, passwordConfirmationError
        } = this.state;
        const usernameVal = username && username.length > 0 ? username : name_suggestion;

        if (!hasValidToken) {
            return this.renderInvalidTokenMessage()
        }

        return (
            <div className="page-registration">
                <Header />
                <div className="page-registration--content">
                    <h1>Registrierung abschließen...</h1>

                    <div className="page-registration--content--card" style={{ paddingTop: '4rem' }}>
                        <Input
                            label="Benutzername"
                            value={usernameVal}
                            onChange={ev => this.setState({ username: ev.target.value, usernameError: '' })}
                            onBlur={ev => this.validateUsername(ev.target.value)}
                            errorText={usernameError}
                            isRequired />

                        <Input
                            label="Neues Passwort"
                            type="password"
                            value={passwd}
                            onChange={ev => this.setState({ passwd: ev.target.value, passwordError: '' })}
                            onBlur={ev => this.validatePassword(ev.target.value)}
                            errorText={passwordError}
                            isRequired />

                        <Input
                            label="Passwort bestätigen"
                            type="password"
                            value={passwd_confirmation}
                            onChange={ev => this.setState({ passwd_confirmation: ev.target.value, passwordConfirmationError: '' })}
                            onBlur={ev => this.validatePasswordConfirmation(ev.target.value)}
                            errorText={passwordConfirmationError}
                            isRequired />

                        <Button
                            text="Absenden"
                            isPrimary
                            onClick={this.handleSubmit.bind(this)}
                            disabled={!this.isValid()} />

                    </div>
                </div>
                <Footer />
            </div>
        );
    }

    renderInvalidTokenMessage() {
        return (
            <div className="page-registration">
                <Header />
                <div className="page-registration--content">
                    <h1>Dein Registrierungsschlüssel ist leider abgelaufen.</h1 >

                    <div className="page-registration--content--card">
                        <h2>Einen neuen Schlüssel erhältst Du hier:</h2>
                        <h2><a href="mailto:it@autohaus-koenig.de">it@autohaus-koenig.de</a></h2>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default RegistrationPage;