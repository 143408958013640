import "./kunden-reaktion.scss";

import React from 'react';
import Button from '@ahk/backend-ui/Button';
import ApiHelper from '../../../helper/ApiHelper';
import koenigLogo from './koenig-logo.svg';

function KundenReaktion(props) {
    const { match = {} } = props;
    const { params = {} } = match;
    const [isServiceAvailable, setServiceState] = React.useState(true);

    React.useEffect(() => {
        (async function () {
            try {
                const { url } = await ApiHelper.GET('/bank11-sofa-url/' + params.dossierId);

                await ApiHelper.PATCH(
                    '/dossiers/' + params.dossierId,
                    JSON.stringify({ $update: { progress_status: "geoeffnet" } })
                );

                if (url) {
                    window.location.assign(url);
                }
            } catch (err) {
                setServiceState(false)
            }
        })();
    }, []);

    return (
        <div className="page-kunden-reaktion">
            {
                isServiceAvailable
                    ? <RedirectCard />
                    : <ErrorCard />
            }
        </div>
    );
}

function Card(props) {
    return (
        <div className="bui-card">
            <img src={koenigLogo} />
            {props.children}
        </div>
    );
}

function RedirectCard(props) {
    return (
        <Card>
            Sie werden in Kürze zur Finanzierungsstrecke der Bank11 weitergeleitet.
        </Card>
    );
}

function ErrorCard(props) {
    return (
        <Card>
            <span>Leider gibt es eine technische Störung, versuchen Sie es später noch einmal oder nehmen Sie gern per E-Mail mit uns Kontakt auf:</span>
            <Button
                text="E-Mail schreiben"
                linkTo="mailto:fahrzeug.uebernahme@autohaus-koenig.de"
                isPrimary />
        </Card>
    );
}

export default KundenReaktion;