import ApiHelper from '@ahk/utils/ApiHelper';

ApiHelper.prototype.getApiUrl = function () {
    if (typeof window !== 'undefined' && typeof window.BASE_URL !== 'undefined') {
        return window.BASE_URL + '/api/v1.0';
    }

    throw new Error('BASE_URL is not set.');
};

ApiHelper.prototype.UPLOAD = function (dossierId, inputName, file, filename) {
    const formData = new FormData();
    formData.append('dossier', dossierId);
    formData.append('file_id', inputName);
    formData.append(inputName, file, filename);

    return fetch(
        this.getApiUrl() + '/dossiers/upload',
        {
            method: 'POST',
            body: formData
        }
    );
};

ApiHelper.prototype.PATCH = function (endpoint, data) {
    const opts = {
        method: 'PATCH',
        body: data
    };

    if (typeof data === 'string') {
        opts.headers = {
            'Content-Type': 'application/json'
        };
    }

    return fetch(this.getApiUrl() + endpoint, opts);
}

ApiHelper.prototype.GET_ZIP = function (endpoint) {
    return fetch(this.getApiUrl() + endpoint, { method: 'GET' });
}

export default new ApiHelper();