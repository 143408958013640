function normalizeCurrency(value = '') {
    let output = value.replace(/\.|€|\s/g, '');
    const [val, decimal = '00'] = output.split(',');
    output = val.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    output += ',' + (decimal + '00').substr(0, 2);

    return output;
}

export default {
    normalizeCurrency
};