import './versendete-vorgaenge.scss';

import React from 'react';
import CommonPage from '../CommonPage';
import SearchBar from '@ahk/backend-ui/SearchBar';
import DataTable from '@ahk/backend-ui/DataTable';
import Pagination from '@ahk/backend-ui/Pagination';
import ApiHelper from '../../../helper/ApiHelper';
import moment from 'moment';
import qs from 'qs';
import CurrencyFormatter from '../../../helper/CurrencyFormatter';
import UserContext from '../../../context/UserContext';
import Icon from '@ahk/backend-ui/Icon';

const CSS_CLASS = 'page-versendete-vorgaenge';

class VersendeteVorgaenge extends React.Component {

    constructor(props) {
        super(props);

        const columnsDefinition = [
            { label: 'Versendet am', set_key: 'created_at', sortable: true, width: 150 },
            { label: 'Fahrgestellnummer', set_key: 'vin', sortable: true, width: 200 },
            { label: 'Kunde', set_key: 'customer', sortable: true },
            { label: 'Vorgangsnummer', set_key: '_id', sortable: true, width: 175 },
            { label: 'Finanzierungspreis', set_key: 'residual_value', sortable: true, width: 176 },
            { label: 'Sachbearbeiter', set_key: 'seller', sortable: true }
        ];

        this.state = {
            columnsDefinition,
            page: 1,
            total: 1,
            total_pages: 1,
            dossiers: [],
            search: '',
            sorting: {}
        };
    }

    async componentDidMount() {
        this._isMounted = true;

        await this.handlePageSelect(1);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async handleSearch(searchText) {
        const { progress_status = false } = this.state;

        const queryObject = {
            page: 1,
            s: searchText
        };

        if (progress_status !== false) {
            queryObject.ps = progress_status;
        }

        try {
            const result = await ApiHelper.GET('/dossiers?' + qs.stringify(queryObject));

            if (this._isMounted && result && result.dossiers) {
                this.setState({
                    ...result,
                    search: searchText
                });
            }
        } catch (err) {
            /* istanbul ignore next */
            console.error(err)
        }
    }

    async handleTableSorting(colIdx, newSortingOrder) {
        const { columnsDefinition, search = '', sorting, progress_status = false } = this.state;
        const newColumsDefinition = [...columnsDefinition];
        let queryObject = {
            page: 1,
            s: search,
            ...sorting,
            [columnsDefinition[colIdx].set_key]: newSortingOrder
        };

        if (search.length === 0) {
            delete queryObject.s;
        }

        if (progress_status !== false) {
            queryObject.ps = progress_status;
        }

        newColumsDefinition[colIdx].order = newSortingOrder;

        try {
            const result = await ApiHelper.GET('/dossiers?' + qs.stringify(queryObject));

            if (this._isMounted && result && result.dossiers) {
                this.setState({
                    ...result,
                    sorting: {
                        ...sorting,
                        [columnsDefinition[colIdx].set_key]: newSortingOrder
                    },
                    columnsDefinition: newColumsDefinition
                });
            }
        } catch (err) {
            /* istanbul ignore next */
            console.error(err)
        }
    }

    async handlePageSelect(newPage) {
        const { search, sorting, progress_status = false } = this.state;
        let queryObject = {
            page: newPage
        };

        if (search && search.length > 0) {
            queryObject.s = search;
        }

        if (Object.keys(sorting).length > 0) {
            queryObject = {
                ...queryObject,
                ...sorting
            };
        }

        if (progress_status !== false) {
            queryObject.ps = progress_status;
        }

        try {
            const result = await ApiHelper.GET('/dossiers?' + qs.stringify(queryObject));

            if (this._isMounted && result && result.dossiers) {
                this.setState({ ...result });
            }
        } catch (err) {
            /* istanbul ignore next */
            console.error(err)
        }
    }

    async handleActionCellClick(row) {
        try {
            const isCurrentStatusClosed = row.progress_status === 'abgeschlossen';
            const progress_status = isCurrentStatusClosed ? row.preclosing_status : 'abgeschlossen';
            const preclosing_status = isCurrentStatusClosed ? '' : row.progress_status;
            const $update = {
                progress_status, preclosing_status
            }

            const patch = await ApiHelper.PATCH('/dossiers/' + row._id, JSON.stringify({ $update }));

            if (patch && patch.status !== 200) {
                throw new Error(`PATCH call auf progress_status: ${progress_status} ist fehlgeschlagen`);
            }

            const updatedDossier = await patch.json();
            const totals = await ApiHelper.GET('/dossiers/statistics');
            const { dossiers } = this.state;

            this.setState({
                dossiers: dossiers.map(d => d._id === row._id ? updatedDossier : d),
                totals
            });

        } catch (err) {
            /* istanbul ignore next */
            console.error(err);
        }
    }

    async handleFiltering(filterButtonIndex) {
        const { search, sorting, page } = this.state;
        const map = ['versendet', 'geoeffnet', 'abgeschlossen'];
        let queryObject = { page, ps: map[filterButtonIndex] };

        if (search && search.length > 0) {
            queryObject.s = search;
        }

        if (Object.keys(sorting).length > 0) {
            queryObject = {
                ...queryObject,
                ...sorting
            };
        }

        try {
            const result = await ApiHelper.GET('/dossiers?' + qs.stringify(queryObject));

            if (this._isMounted && result && result.dossiers) {
                this.setState({ ...result, progress_status: map[filterButtonIndex] });
            }
        } catch (err) {
            /* istanbul ignore next */
            console.error(err)
        }
    }

    async handleResetFiltering() {
        const { search, sorting, page } = this.state;
        let queryObject = { page };

        if (search && search.length > 0) {
            queryObject.s = search;
        }

        if (Object.keys(sorting).length > 0) {
            queryObject = {
                ...queryObject,
                ...sorting
            };
        }

        try {
            const result = await ApiHelper.GET('/dossiers?' + qs.stringify(queryObject));

            if (this._isMounted && result && result.dossiers) {
                this.setState({ ...result, progress_status: false });
            }
        } catch (err) {
            /* istanbul ignore next */
            console.error(err)
        }
    }

    render() {
        const { columnsDefinition, dossiers, page, totals = {}, total_pages } = this.state;
        const filterButtons = [
            { label: 'Versendet', counter: totals.versendet },
            { label: 'Bei Bank11', counter: totals.geoeffnet },
            { label: 'Abgeschlossen', counter: totals.abgeschlossen }
        ];

        const actionColumnCssMap = {
            neu: '',
            versendet: '',
            geoeffnet: '',
            abgeschlossen: 'checked'
        };
        let columnsDef = [].concat(
            columnsDefinition,
            {
                label: '',
                set_key: 'progress_status',
                comp: p => <Icon variant="checkmark" color="white" />,
                css_filter: rowData => 'action-column ' + actionColumnCssMap[rowData.progress_status],
                onCellClick: this.handleActionCellClick.bind(this),
                width: 60
            }
        );

        const dataset = dossiers.reduce((result, dossier) => {
            const { created_at, seller, firstname, lastname, company } = dossier;

            result.push({
                ...dossier,
                residual_value: CurrencyFormatter.normalizeCurrency(dossier.residual_value) + ' €',
                created_at: moment(created_at, 'YYYYMMDDHHmmss').format('DD.MM.YYYY HH:mm'),
                seller: seller.firstname + ' ' + seller.lastname,
                customer: company && company.length > 0 ? company : firstname + ' ' + lastname
            });

            return result;
        }, []);

        return (
            <CommonPage className={CSS_CLASS}>
                <h1 className={CSS_CLASS + '--headline'}>Alle versendeten Vorgänge im Überblick.</h1>

                <SearchBar
                    searchHint="VIN, Kundenname oder Vorgangsnummer..."
                    onSearch={this.handleSearch.bind(this)}
                    filterButtons={filterButtons}
                    onFiltering={this.handleFiltering.bind(this)}
                    onResetFiltering={this.handleResetFiltering.bind(this)}
                    withToggleButtons />

                <UserContext.Consumer>
                    {user => {
                        if (!user || !Array.isArray(user.role) || user.role.indexOf('supervisor') === -1) {
                            columnsDef = columnsDef.filter(col => col.label.length > 0);
                        }
                        return (
                            <DataTable
                                columns={columnsDef}
                                dataset={dataset}
                                onRowClick={dossier => window.location.assign(`/vorgaenge/${dossier._id}/`)}
                                onOrderChange={this.handleTableSorting.bind(this)} />
                        );
                    }}
                </UserContext.Consumer>

                <div className={CSS_CLASS + '--pagination--wrapper'}>
                    <Pagination
                        currentPage={page}
                        total={total_pages}
                        onNextClick={this.handlePageSelect.bind(this)}
                        onPrevClick={this.handlePageSelect.bind(this)}
                        onPageSelect={this.handlePageSelect.bind(this)} />
                </div>

            </CommonPage>
        );
    }
}

export default VersendeteVorgaenge;