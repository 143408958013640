import './app.scss';

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import NeuerVorgangPage from './pages/NeuerVorgang';
import ApiHelper from '../helper/ApiHelper';
import UserContext from '../context/UserContext';
import ThemeContext from '@ahk/backend-ui/ThemeContext';
import DetailVorgangPage from './pages/DetailVorgangPage';
import VersendeteVorgaenge from './pages/VersendeteVorgaenge';
import RegistrationPage from './pages/RegistrationPage';
import PasswotResetPage from './pages/PasswortResetPage';
import KundenReaktion from './pages/KundenReaktion';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {}
        };
    }

    async componentDidMount() {
        this.__isMounted = true;
        const newState = {};

        if (typeof window === 'undefined' || !window.location) {
            return;
        }

        try {
            const isLoginPage = window.location.pathname.indexOf('/login') === 0;
            const isRegistrationPage = window.location.pathname.indexOf('/registration') === 0;
            const isCustomerReaction = window.location.pathname.indexOf('/anschlussfinanzierung') === 0;

            if (!isLoginPage && !isRegistrationPage && !isCustomerReaction) {
                await ApiHelper.GET('/auth/refresh');

                const me = await ApiHelper.GET('/users/me');

                if (me) {
                    newState.user = me;
                }
            }

        } catch (err) {
            window.location.assign('/login');
        }

        /* istanbul ignore else */
        if (this.__isMounted) {
            this.setState({ ...newState });
        }
    }

    componentWillUnmount() {
        this.__isMounted = false;
    }

    render() {
        const theme = {
            light: {
                primary: '#7CB932',
                primary_contrast: '#FFFFFF'
            }
        };

        return (
            <ThemeContext.Provider value={theme}>
                <UserContext.Provider value={{ ...this.state.user }}>
                    <Switch>
                        <Route path="/login/" component={LoginPage} exact />
                        <Route path="/login/reset" component={PasswotResetPage} exact />
                        <Route path="/registration" component={RegistrationPage} />
                        <Route path="/vorgaenge/" component={VersendeteVorgaenge} exact />
                        <Route path="/vorgaenge/neu/" component={NeuerVorgangPage} exact />
                        <Route path="/vorgaenge/:dossierId/" component={DetailVorgangPage} exact />
                        <Route path="/anschlussfinanzierung/:dossierId/" component={KundenReaktion} exact />
                    </Switch>
                </UserContext.Provider>
            </ThemeContext.Provider>
        );
    }
}

export default App;
