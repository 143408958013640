import VinHelper from '@ahk/utils/VinHelper';

const isFormValid = dossier => {
    const {
        salutation, firstname, lastname,
        company,
        street, nr, postal_code, location,
        email, phone,
        vin,
        residual_value,
        sales_type
    } = dossier;

    const formFields = [
        street, nr, postal_code, location,
        email, phone,
        residual_value
    ];

    if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
        return false;
    }

    if (!VinHelper.isValid(vin)) {
        return false;
    }
    
    if (sales_type === 'commercial') {
        formFields.push(company);
    } else {
        formFields.push(salutation, firstname, lastname);
    }

    for (let i = 0; i < formFields.length; i++) {
        const field = formFields[i];

        if (typeof field === 'undefined' || field === null || field.length === 0) {
            return false;
        }
    }

    return true;
};

export default {
    isFormValid
};