import './detail-vorgang.scss';

import React from 'react';
import CommonPage from '../CommonPage';
import Button from '@ahk/backend-ui/Button';
import moment from 'moment';
import InfoBox from '@ahk/backend-ui/InfoBox';
import ApiHelper from '../../../helper/ApiHelper';
import FileHandler from '@ahk/backend-ui/FileHandler';
import UserContext from '../../../context/UserContext';
import Textarea from '@ahk/backend-ui/Textarea';

import 'moment-timezone';
import 'moment/locale/de';
import CurrencyFormatter from '../../../helper/CurrencyFormatter';

const CSS_PREFIX = 'page-vorgang-detail';
const CSS_PREFIX_CONTENT = CSS_PREFIX + "--content";

class DetailVorgangPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ...props.dossier
        };
    }

    async componentDidMount() {
        this.__isMounted = true;

        try {
            const { match = {} } = this.props;
            const { params = {} } = match;
            const { dossierId = 'not_found' } = params;
            const dossier = await ApiHelper.GET('/dossiers/' + dossierId);

            /* istanbul ignore else */
            if (dossier && this.__isMounted) {
                this.setState({ ...dossier });
            }
        } catch (err) {
            window.location.assign('/login/');
        }
    }

    componentWillUnmount() {
        this.__isMounted = false;
    }

    async handleDownloadAll() {
        const { _id } = this.state;

        try {
            const res = await ApiHelper.GET_ZIP('/dossiers/download/' + _id);
            const blob = await res.blob();

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');

            a.href = url;
            a.download = _id + '.zip';

            document.body.appendChild(a);

            a.click();
            a.remove();
        } catch (err) {
            console.error(err);
        }
    }

    async handleProgressStatus() {
        try {
            const isCurrentStatusClosed = this.state.progress_status === 'abgeschlossen';
            const progress_status = isCurrentStatusClosed ? this.state.preclosing_status : 'abgeschlossen';
            const preclosing_status = isCurrentStatusClosed ? '' : this.state.progress_status;
            const $update = {
                progress_status, preclosing_status
            }

            const patch = await ApiHelper.PATCH('/dossiers/' + this.state._id, JSON.stringify({ $update }));

            if (patch && patch.status !== 200) {
                throw new Error(`PATCH call auf progress_status: "${progress_status}" ist fehlgeschlagen`);
            }

            const updatedDossier = await patch.json();

            this.setState({ ...updatedDossier });

        } catch (err) {
            /* istanbul ignore next */
            console.error(err);
        }
    }

    async handleHintSaving() {
        const { _id, hint_text = '' } = this.state;
        let saving_hint = 'Text wurde gespeichert.';

        this.setState({
            is_button_blocked: true
        });
        
        try {
            const patch = await ApiHelper.PATCH('/dossiers/' + _id, JSON.stringify({ $update: { hint_text } }));
            
            if (patch && patch.status !== 200) {
                throw new Error(`PATCH call auf hint_text: ${hint_text} ist fehlgeschlagen`);
            }
            
        } catch (err) {
            saving_hint = 'Text konnte nicht gespeichert werden.'
        } finally {
            this.setState({ saving_hint });
        }
    }
    
    handleTextareaChange(hint_text) {
        this.setState({
            hint_text,
            saving_hint: '',
            is_button_blocked: false
        });
    }

    render() {
        const { _id, created_at, mail_text, files = {}, progress_status } = this.state;

        const dossierCreatedAtMoment =
            moment(created_at, 'YYYYMMDDHHmmss')
                .tz('Europe/Berlin');

        return (
            <CommonPage className={CSS_PREFIX}>
                <div className={CSS_PREFIX_CONTENT}>
                    <div className={CSS_PREFIX_CONTENT + '--header'}>
                        <Button
                            text="Zurück"
                            linkTo="/vorgaenge/"
                            className="btn-back"
                            icon="arrow_left"
                            iconPosition="left"
                        />
                        <div className={CSS_PREFIX_CONTENT + '--header--headline'}>
                            <h1>{_id}{progress_status === 'abgeschlossen' ? ' (Abgeschlossen)' : null}</h1>
                            <h2>{dossierCreatedAtMoment.format('DD.MM.YYYY [um] HH:mm [Uhr]')}</h2>
                        </div>
                        <div>
                            <UserContext.Consumer>
                                {user => {
                                    if (user && Array.isArray(user.role) && user.role.indexOf('supervisor') > -1) {
                                        const btnLabel = progress_status === 'abgeschlossen' ? 'Vorgang öffnen' : 'Vorgang abschließen';

                                        return (
                                            <Button
                                                text={btnLabel}
                                                onClick={this.handleProgressStatus.bind(this)}
                                                className="btn-completed"
                                                isPrimary />
                                        );
                                    }
                                }}
                            </UserContext.Consumer>
                        </div>
                    </div>
                    <div className={CSS_PREFIX_CONTENT + '--info-boxes'}>
                        {this.renderCustomer()}
                        {this.renderSeller()}
                    </div>

                    <InfoBox
                        className="list-customer-message"
                        list={[
                            ['Versendeter Text an den Kunden', mail_text]
                        ]}
                    />

                    <h2>Optionale Unterlagen</h2>
                    <FileHandler
                        modus="downloading"
                        label="Kaufvertrag (PDF-Dokument)"
                        onDownloadAll={this.handleDownloadAll.bind(this)}
                        files={files.purchase_contract} />

                </div>
            </CommonPage>
        )
    }

    renderCustomer() {
        const {
            salutation = '', firstname = '', lastname = '',
            company = '',
            street, nr, postal_code, location,
            email, phone,
            vin,
            sales_type
        } = this.state;

        const customerList = [
            ['Kunde', `${salutation} ${firstname} ${lastname}`],
            ['Firma', company],
            [],
            ['Adresse', `${street} ${nr}, ${postal_code} ${location}`],
            ['E-Mail', email],
            ['Telefon', phone],
            [],
            ['Fahrgestellnummer', vin],
            [],
            ['Kundenart', sales_type === 'private' ? 'Privat' : 'Gewerbe']
        ];

        company.length === 0 && customerList.splice(1, 1);

        salutation.length === 0 &&
            firstname.length === 0 &&
            lastname.length === 0 &&
            customerList.splice(0, 1);

        return (
            <InfoBox
                className="list-customer"
                list={customerList}
            />
        )
    }

    renderSeller() {
        const { residual_value, seller = {}, hint_text = '', saving_hint = '', is_button_blocked = true } = this.state;
        const CSS_RESIDUAL_VALUE = CSS_PREFIX_CONTENT + '--residual-value';
        const CSS_ADVISOR = CSS_PREFIX_CONTENT + '--advisor';
        const CSS_HINT_AREA = CSS_PREFIX_CONTENT + '--hint-area';

        const savingHintClasses = [CSS_HINT_AREA + '--saving-hint'];
        if (saving_hint.indexOf('konnte nicht') !== -1) {
            savingHintClasses.push('error');
        }

        return (
            <div className={CSS_PREFIX_CONTENT + '--info-boxes--right'}>
                <div className={CSS_RESIDUAL_VALUE}>
                    <span className={CSS_RESIDUAL_VALUE + '--label'}>Finanzierungssumme (Restwert inkl. MwSt.)</span>
                    <span className={CSS_RESIDUAL_VALUE + '--price'}>{CurrencyFormatter.normalizeCurrency(residual_value)} €</span>
                </div>
                <div className={CSS_ADVISOR}>
                    <div className={CSS_ADVISOR + '--name'}>
                        <span className={CSS_ADVISOR + '--label'}>Sachbearbeiter:</span>
                        <span className={CSS_ADVISOR + '--value'}>{seller.firstname} {seller.lastname}</span>
                    </div>
                    <div className={CSS_ADVISOR + '--seller-nr'}>
                        <span className={CSS_ADVISOR + '--label'}>Verkäufer-Nr.:</span>
                        <span className={CSS_ADVISOR + '--value'}>{seller.salesman_nr}</span>
                    </div>
                    <div className={CSS_ADVISOR + '--email'}>
                        <span className={CSS_ADVISOR + '--label'}>E-Mail-Adresse:</span>
                        <span className={CSS_ADVISOR + '--value'}>{seller.email}</span>
                    </div>
                    <div className={CSS_ADVISOR + '--phone'}>
                        <span className={CSS_ADVISOR + '--label'}>Telefon:</span>
                        <span className={CSS_ADVISOR + '--value'}>{seller.phone}</span>
                    </div>
                </div>
                <div className={CSS_HINT_AREA}>
                    <Textarea
                        label="Nachricht"
                        rows="5"
                        onChange={this.handleTextareaChange.bind(this)}
                        value={hint_text} />

                    <Button
                        text="Text speichern"
                        onClick={this.handleHintSaving.bind(this)}
                        disabled={is_button_blocked}
                        isPrimary />

                    {saving_hint && saving_hint.length > 0 &&
                        <span className={savingHintClasses.join(' ')}>{saving_hint}</span>
                    }
                </div>
            </div>
        );
    }
}

export default DetailVorgangPage;