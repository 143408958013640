import './neuer-vorgang.scss';

import React from 'react';
import CommonPage from '../CommonPage';
import UserContext from '../../../context/UserContext';
import DossierForm from '../../../components/DossierForm';
import Button from '@ahk/backend-ui/Button';
import ValidationHelper from '../../../helper/ValidationHelper';
import ApiHelper from '../../../helper/ApiHelper';
import FileHandler from '@ahk/backend-ui/FileHandler';
import Overlay from '@ahk/backend-ui/Overlay';
import SimpleTextEditor from '@ahk/backend-ui/SimpleTextEditor';
import QuestionSection from "./components/QuestionSection";

const CSS_PREFIX = "page-neuer-vorgang";
const CSS_PREFIX_CONTENT = CSS_PREFIX + "--content";
const UPLOADED = 2;

class NeuerVorgangPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            salutation: '', firstname: '', lastname: '',
            company: '',
            street: '', nr: '', postal_code: '', location: '',
            email: '', phone: '',
            vin: '', residual_value: '',
            uploadQueue: [],
            purchaseContractFiles: [],
            aborted: false,
            customerMessage: '',
            defaultMailText: '',
            sales_type: ''
        };
    }

    async componentDidMount() {
        this._isMounted = true;

        try {
            const result = await ApiHelper.GET('/contents/mail_default');

            /* istanbul ignore else */
            if (result && this._isMounted) {
                this.setState({ defaultMailText: result.text })
            }
        } catch (err) {
            console.error(err);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async handleSubmit() {

        /* istanbul ignore else */
        if (typeof window !== 'undefined' && window.document) {
            window.scrollTo(0, 0);
        }

        const {
            salutation, firstname, lastname,
            company,
            street, nr, postal_code, location,
            email, phone,
            vin, residual_value,
            purchaseContractFiles,
            customerMessage, defaultMailText,
            sales_type
        } = this.state;

        const dossierData = {
            seller: this.context,
            salutation, firstname, lastname,
            company,
            street, nr, postal_code, location,
            email, phone,
            vin, residual_value,
            doc_count: purchaseContractFiles.length,
            mail_text: customerMessage.length > 0 ? customerMessage : defaultMailText,
            sales_type
        };

        const uploadQueue = [
            ['purchase_contract', 'Kaufvertrag (PDF-Dokument)', [], purchaseContractFiles]
        ];
        this.setState({ uploadQueue: purchaseContractFiles.length > 0 ? uploadQueue : [] });

        this.setState({ aborted: false });

        try {
            const dossier = await ApiHelper.POST('/dossiers', dossierData);

            for (let i = 0; i < purchaseContractFiles.length; i++) {
                const { aborted } = this.state;
                const file = purchaseContractFiles[i];

                if (aborted === true) {
                    this.setState({ uploadQueue: [] });
                    return;
                }

                try {
                    const upload = await ApiHelper.UPLOAD(dossier._id, 'purchase_contract', file, file.name);

                    if (upload && upload.status !== 200) {
                        uploadQueue[0].push(' Fehler');
                        return this.setState({ uploadQueue });
                    }

                    uploadQueue[0][UPLOADED].push(file);
                } catch (uploadErr) {
                    uploadQueue[0].push(' Fehler');
                }

                this.setState({ uploadQueue });
            }

            const patch = await ApiHelper.PATCH(
                '/dossiers/' + dossier._id,
                JSON.stringify({ $update: { progress_status: 'neu' } })
            );

            if (patch && patch.status !== 200) {
                throw new Error("PATCH call auf progress_status: neu ist fehlgeschlagen");
            }

            window.location.assign(`/vorgaenge/${dossier._id}/`);
        } catch (commonErr) {
            console.error(commonErr.message ? commonErr.message : commonErr);
            this.setState({ showStorageError: true });
        }
    }

    render() {
        const user = this.context;
        const {
            uploadQueue, aborted, defaultMailText,
            customerMessage, purchaseContractFiles,
            sales_type
        } = this.state;
        const classes = ['dossier--form-upload', 'hidden'];

        if (sales_type === 'private' || sales_type === 'commercial') {
            classes.pop();
        }

        return (
            <CommonPage className={CSS_PREFIX}>
                <div className={CSS_PREFIX_CONTENT}>
                    <h1 className={CSS_PREFIX_CONTENT + "--welcome"}>Hallo...{user && user.username}, <br />
                        starte jetzt einen neuen Vorgang.</h1>

                    <QuestionSection
                        {...this.state}
                        onChange={(key, val) => this.setState({ [key]: val })} />

                    <div className={classes.join(' ')}>
                        <DossierForm
                            {...this.state}
                            onChange={(key, val) => this.setState({ [key]: val })} />

                        <h2>Nachricht an den Kunden:</h2>
                        <SimpleTextEditor
                            defaultText={defaultMailText}
                            customText={customerMessage}
                            onChange={(customText) => this.setState({ customerMessage: customText.replace(/\r\n|\r|\n/g, '\n') })} />

                        <h2>Optionale Unterlagen</h2>
                        <FileHandler
                            modus="collecting"
                            label="Kaufvertrag (PDF-Dokument)"
                            files={purchaseContractFiles}
                            onChange={purchaseContractFiles => this.setState({ purchaseContractFiles })} />

                        <Button
                            text="Vorgang jetzt abschließen und absenden"
                            className={CSS_PREFIX_CONTENT + "--submit"}
                            onClick={this.handleSubmit.bind(this)}
                            disabled={!ValidationHelper.isFormValid(this.state)}
                            isPrimary />
                    </div>
                </div>

                {uploadQueue.length > 0 && aborted === false &&
                    this.renderOverlay()
                }
            </CommonPage>
        );
    }

    renderOverlay() {
        const { uploadQueue, showStorageError = false } = this.state;
        const classStatus = [];

        return (
            <Overlay className={CSS_PREFIX + '--overlay--upload'} hideCloseButton>
                <b className={CSS_PREFIX + '--overlay--upload--headline'}>Vorgang wird gespeichert...</b>

                {showStorageError === false &&
                    uploadQueue.map((queueItem, idx) => {
                        const [_, label, uploaded, upcoming, error] = queueItem;
                        let statusText = `${uploaded.length}/${upcoming.length}`;

                        if (error) {
                            statusText = error;
                            classStatus.push('error');
                        }

                        return (
                            <div key={'upload-row-' + idx} className={CSS_PREFIX + '--overlay--upload--row'}>
                                <span>{label}</span>
                                <span className={classStatus.join(" ")}>{statusText}</span>
                            </div>
                        );
                    })
                }

                {showStorageError === true &&
                    <div className={CSS_PREFIX + '--overlay--upload--error'}>Beim Speichern des Vorganges ist ein Fehler aufgetreten.</div>
                }

                <Button text="Abbrechen" onClick={() => this.setState({ aborted: true })} isPrimary />
            </Overlay>
        );
    }
}

NeuerVorgangPage.contextType = UserContext;

export default NeuerVorgangPage;